import one from "../../assets/home/images/one.svg";
import two from "../../assets/home/images/two.svg";
import three from "../../assets/home/images/three.svg";
import four from "../../assets/home/images/four.svg";
import five from "../../assets/home/images/five.svg";
import six from "../../assets/home/images/six.svg";

import logo1 from "../../assets/navbar/logo1.png";

import java from "../../assets/home/images/technologies/java.svg";
import NET from "../../assets/home/images/technologies/NET.png";
import angular from "../../assets/home/images/technologies/angular.svg";
import api from "../../assets/home/images/technologies/api.png";
import aspNet from "../../assets/home/images/technologies/aspNet.png";
import bootstrap from "../../assets/home/images/technologies/bootstrap.png";
import css from "../../assets/home/images/technologies/css.svg";
import django from "../../assets/home/images/technologies/django.png";
import flutter from "../../assets/home/images/technologies/flutter.svg";
import html from "../../assets/home/images/technologies/html.svg";
import javascript from "../../assets/home/images/technologies/javascript.svg";
import kotlin from "../../assets/home/images/technologies/kotlin.svg";
import php from "../../assets/home/images/technologies/php.svg";
import python from "../../assets/home/images/technologies/python.png";
import reactNative from "../../assets/home/images/technologies/reactNative.png";
import react from "../../assets/home/images/technologies/react.svg";

import JoyRajAcademy from "../../assets/home/images/clients/JoyRajAcademy.png";
import mauryaService from "../../assets/home/images/clients/mauryaService.jpeg";
import Scc from "../../assets/home/images/clients/Scc.PNG";
import krushConsultant from "../../assets/home/images/clients/krushConsultant.webp";
import dattafibre from "../../assets/home/images/clients/dattafibre.png";
import hoh from "../../assets/home/images/clients/hoh.png";
import agi from "../../assets/home/images/clients/agi.png";
import TrendPick from "../../assets/home/images/clients/TrendPick.png";

import j from "../../assets/home/images/portfolio/j.PNG";
import k from "../../assets/home/images/portfolio/k.png";
import m from "../../assets/home/images/portfolio/m.png";
import s from "../../assets/home/images/portfolio/s.png";
import dattafibrep from "../../assets/home/images/portfolio/dattafibrep.png";
import hohp from "../../assets/home/images/portfolio/hohp.png";
import agip from "../../assets/home/images/portfolio/agip.png";
import trendpick from "../../assets/home/images/portfolio/trendpick.png";

import contact from "../../assets/home/images/contact/contact.png";

import logo from "../../assets/footer/logo.png";

import servicesImage from "../../assets/services/services/servicesImage.png";
import websiteDevelopmentImage from "../../assets/services/websiteDevelopmentImage.png";
import softwareDevelopmentImage from "../../assets/services/softwareDevelopmentImage.png";
import appsDevelopmentImage from "../../assets/services/appsDevelopmentImage.png";
import biDashboardsImage from "../../assets/services/biDashboardsImage.jpg";
import seoOptimizationImage from "../../assets/services/seoOptimizationImage.jpg";
import digitalMarketingImage from "../../assets/services/digitalMarketingImage.png";

import getItOnWebsite from "../../assets/clients/getItOnWebsite.png";

import products from "../../assets/products/products/products.png";
import AFAUM from "../../assets/products/AFAUM.jpeg";
import ams from "../../assets/products/ams.png";
import BarcodeG from "../../assets/products/BarcodeG.png";
import DCMS from "../../assets/products/DCMS.webp";
import EMS from "../../assets/products/EMS.avif";
import erp from "../../assets/products/erp.png";
import HMS from "../../assets/products/HMS.jpeg";
import hospital from "../../assets/products/hospital.png";
import HRM from "../../assets/products/HRM.png";
import HTS from "../../assets/products/HTS.png";
import IMAUM from "../../assets/products/IMAUM.png";
import LBAOMS from "../../assets/products/LBAOMS.webp";
import LM from "../../assets/products/LM.png";
import LMS from "../../assets/products/LMS.png";
import MVFS from "../../assets/products/MVFS.webp";
import ParkingMS from "../../assets/products/ParkingMS.webp";
import PharmacyMS from "../../assets/products/PharmacyMS.webp";
import PMS from "../../assets/products/PMS.png";
import posPharmacy from "../../assets/products/posPharmacy.png";
import posStock from "../../assets/products/posStock.jpg";
import POSR from "../../assets/products/POSR.png";
import scums from "../../assets/products/scums.webp";
import UM from "../../assets/products/UM.webp";
import UMARTCA from "../../assets/products/UMARTCA.webp";
import WARB from "../../assets/products/WARB.png";

import ai from "../../assets/blog/ai.webp";
import app from "../../assets/blog/app.webp";
import website from "../../assets/blog/website.png";
import software from "../../assets/blog/software.png";
import ecommerce from "../../assets/blog/ecommerce.png";
import machine from "../../assets/blog/machine.jpg";

import contactPng from "../../assets/contact/contactPng.png";

import app2 from "../../assets/blog/blog app2.webp";
import ecommerce2 from "../../assets/blog/blog ecommerce2.png";
import machine2 from "../../assets/blog/blog machine2.jpg";
import software2 from "../../assets/blog/blog software2.jpg";
import website2 from "../../assets/blog/website2.png";

import blog from "../../assets/blog/blog/blog.png";

const Images = {
  one,
  two,
  three,
  four,
  five,
  six,

  logo1,

  // products

  // posStock,

  // blog

  blog,
  ecommerce2,
  machine2,
  software2,
  website2,

  app2,

  // all services

  // contact

  contactPng,

  // blogs

  ai,
  app,
  website,
  software,
  ecommerce,
  machine,

  // products

  products,
  UM,
  MVFS,
  WARB,
  PMS,
  UMARTCA,
  scums,
  POSR,
  posStock,
  posPharmacy,
  PharmacyMS,
  ParkingMS,
  LMS,
  LM,
  LBAOMS,
  IMAUM,
  AFAUM,
  ams,
  BarcodeG,
  DCMS,
  EMS,
  erp,
  HMS,
  hospital,
  HRM,
  HTS,

  // Clients
  getItOnWebsite,

  // services

  servicesImage,
  websiteDevelopmentImage,
  softwareDevelopmentImage,
  appsDevelopmentImage,
  biDashboardsImage,
  seoOptimizationImage,
  digitalMarketingImage,

  // contact
  contact,

  // portfolio
  j,
  k,
  m,
  s,
  dattafibrep,
  hohp,
  agip,
  trendpick,

  // clients
  JoyRajAcademy,
  mauryaService,
  Scc,
  krushConsultant,
  dattafibre,
  hoh,
  agi,
  TrendPick,


  //  Technologies

  html,
  css,
  django,
  flutter,
  aspNet,
  api,
  bootstrap,
  NET,
  javascript,
  angular,
  kotlin,
  php,
  python,
  reactNative,
  java,
  react,

  // footer image

  logo,
};

export default Images;
